/*
* Omapost
*/

/* Sass variables css */
@import "resources/assets/sass/_variables.scss";

* {
        font-family: 'Asap', sans-serif;
}

body {
        margin: 0;
}

img {
        display: block;
        margin: auto;
        width: 100%;
}

a {
        color: #00b4cc;
        text-decoration: none;
}

/* Disable ugly boxes around images in IE10 */
a img{
        border: 0px;
}

/* Selecting text */
::-moz-selection {
        background-color: $orange;
        color: #FFFFFF;
}
::selection {
        background-color: $orange;
        color: #FFFFFF;
}

/* Standard animations */

.anim150 {
    transition: all 150ms ease-in-out;
}

.anim250 {
    transition: all 250ms ease-in-out;
}


.animated {
    /*The Null Transform Hack*/
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.fade-fast {
    opacity: 0;
    -webkit-animation: lc-appear 1s ease-in-out 1s;
    animation: lc-appear 1s ease-in-out 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.fade-medium {
    opacity: 0;
    -webkit-animation: lc-appear 1.4s ease-in-out 2s;
    animation: lc-appear 1.4s ease-in-out 2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.fade-slow { 
    opacity: 0;
    -webkit-animation: lc-appear 1.4s ease-in-out 3s;
    animation: lc-appear 1.4s ease-in-out 3s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@-webkit-keyframes lc-appear {
    from    {opacity: 0;}
    to      {opacity: 1;}
} 

/* Standard syntax */ 
@keyframes lc-appear {
    from    {opacity: 0;}
    to      {opacity: 1;}
}

/* STANDARD FLEX STRUCTURES */


/* STRUCTURAL STYLING */

.flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.fluid {
    -webkit-box-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;

    position: relative;
}

.row {
    -webkit-flex-direction: row !important;
    flex-direction: row !important;

    -webkit-align-items: flex-start !important;
    align-items: flex-start !important;
    /* Bootstrap css also has a row class giving it unwanted margins */
    margin: 0;
}

.column {
    -webkit-flex-direction: column;
    flex-direction: column;
}

/* column to row list view */
.column-to-row {
    -webkit-flex-direction: column;
    flex-direction: column;
}
.list .column-to-row {
    -webkit-flex-direction: row;
    flex-direction: row;
}

/* row to column list view */
.row-to-column {
    -webkit-flex-direction: row;
    flex-direction: row;
}

.flex-wrap {
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    margin-bottom: 5px;
}

.justify {
    -webkit-justify-content: center;
    justify-content: center;

    -webkit-align-items: center;
    align-items: center;
}

.justify-left {
    -webkit-justify-content: center;
    justify-content: center;

    -webkit-align-items: flex-start;
    align-items: flex-start;
}

.left-center {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;

    -webkit-align-items: center;
    align-items: center;
}

.space-between {
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.space-around {
    -webkit-justify-content: space-around;
    justify-content: space-around;
}

.center {
    text-align: center;
}

.padding {
    padding: 30px;
}

.ns {
    -webkit-user-select: none;
    -moz-user-select: none;
    -user-select: none;
    cursor: default;
}


/* BODY CSS */

body {
    font-family: 'Asap', sans-serif;
    color: #4b5263;
}


/* Wrapper */

.wrapper {
    position: relative;
    width: 900px;
    max-width: 100%;
    margin: 0 auto;

    p {
        padding: 20px 15px 20px 15px;
        margin: 0;
        line-height: 20px;
        font-family: 'Asap', sans-serif;
        color: #4b5263;
        letter-spacing: 0.5px;
    }
}

.alinea-center {
    margin: 0 auto;
    max-width: 600px;
}

/* BUTTONS */ 

.button-box {
        width: 100%;
}
button,
.button {
    position: relative;
    padding: 15px 20px;
    margin: 0 50px;
    font-size: 20px;
    color: #fff;
    background: $orange;
    letter-spacing: 0.5px;
    border: none;
    border-right: 1px solid #D4782A;
    border-bottom: 1px solid #D4782A;
    border-radius: 3px;
    box-shadow: none;

    &:hover {
        background: #FFC336;
    }
}


/* HEADERS */

h1 {
    padding: 0 15px;
    color: $orange;
    font-weight: bolder;
    margin: .67em 0 0 0;
    font-family: DINNextSlabPro-Light;
    font-weight: normal;
    font-style: normal;
    font-size: 2.3em;
    text-transform: uppercase;
}

h2 em {
    color: #4b5263;
}

h2 {
    padding: 0 15px;
    color: $orange;
    font-size: 2em;
    font-weight: bolder;
    margin: .67em 0 0 0;
}
h2 a {
    color: $orange;
}
.green {
    color: #9D9D57
}

h3 {
    color: #9D9D57;
}

/* INPUTS */

/* PLACEHOLDER COLOR */

::-webkit-input-placeholder { /* WebKit browsers */
    color:    #ccc;
    color:    rgba(0, 0, 30, .4);
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:    #ccc;
    color:    rgba(0, 0, 30, .4);
    opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:    #ccc;
    color:    rgba(0, 0, 30, .4);
    opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    color:    #ccc;
    color:    rgba(0, 0, 30, .4);
}


[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="checkbox"]:checked,
[type="checkbox"]:not(:checked),
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
[type="checkbox"]:checked:before,
[type="checkbox"]:not(:checked):before,
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type="checkbox"]:checked:after,
[type="checkbox"]:not(:checked):after,
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: $orange;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="checkbox"]:not(:checked):after,
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="checkbox"]:checked:after,
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}


.form-group {
    padding: 10px;

    -webkit-justify-content: flex-start;
    justify-content: flex-start;

    -webkit-align-items: center;
    align-items: center;

    label {
        display: inline-block;
        width: 160px;
        color: $orange;
    }
    input {
        -webkit-box-flex: 1 1 auto;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;

        position: relative;
        padding: 8px 0;
        border-color: transparent;
        border-bottom: 1px solid #ccd;
        outline: none;

        &:focus {
            border-bottom: 1px solid $orange;
        }
    }
    .button {
        width: 100%;
        margin: 0;
        padding: 15px;
    }
}


.form-errornotice {
    color: #991b34;
}
.alert-info {
    color: #9D9D57;
    margin-left: 15px;
}

/* General CSS */ 

.opening {
    display: inline-block;
    font-size: 3em;
    position: relative;
    padding: 15px 20px;
    margin: 0 50px;
    color: #fff;
    background: $orange;
    letter-spacing: 0.5px;
    border: none;
    border-right: 1px solid #D4782A;
    border-bottom: 1px solid #D4782A;
    border-radius: 3px;
    box-shadow: none;

    &:hover {
        background: #FFC336;
    }
}

.promo-text .mainspan {
    max-width: 750px;
    color: $orange;
    font-size: 1.5em;
    font-weight: bolder;
    margin: 0 auto;
    padding: 15px;

    .subspan {
        padding: 15px;
    }
}

.orange {
    color: $orange;
    font-weight: bolder;
}

.orange-background {
    padding: 20px 0;
    color: #fff;
    background: $orange;
    background: linear-gradient(5deg, #c0671b, $orange, #f0a75b) fixed;
    h1 {
        color: #fff;
    }

}

.partner-logo-slimmer {
    width: 72%;
}

/* --- HEADER SECTION ---- */

header {
    position: relative;
    width: 100%;
    background: #fff;

    .wrapper {
        border-bottom: 2px solid #4b5263;
    }
    .stamps {
        position: relative;
        display: block;
        height: 100px;
    }
}


.headerimage {
    position: relative;
    display: block;
    max-width: 300px;
    margin: 0 auto;
}

.action-header {
    height: 120px;
}
/* postzegel */
.main-stamp {
    position: relative;
    float: right;
    width: 12%;
    min-width: 80px;
    margin: 0 1%;
    z-index: 9;
}
.sub-stamp {
    position: relative;
    float: right;
    width: 9%;
    min-width: 60px;
    margin: 0 1%;
    z-index: 9;
}


/* sections */

#Sec1,#Sec2,#Sec3,#Sec4,#sec5,#sec6 {
        margin: 20px 0 30px 0;        
}

#Sec3b, #Sec3b h1, #Sec3b p {
        color: #ffffff !important;
}
#Sec3b {
        margin-top: -80px;
        padding-bottom: 20px;
        background: #9D9D57;       
}

#mc_embed_signup {
        background: none !important;
        max-width: 80% !important;
        margin: 0 auto !important;
}
#mc_embed_signup .email {
        padding: 24px !important;
        /*background: none !important;*/
        /*border: none !important;*/
        /*border-radius: none !important;*/
        /*border-bottom: 1px solid #fff !important;*/
}

#mc_embed_signup .button {
        height: 50px!important;
        background: $orange !important;
        border: 1px solid rgba(255, 255, 255, .5) !important;
}
#mc_embed_signup .button:hover {
        background: $orange !important;
        border: 1px solid rgba(255, 255, 255, .5) !important;
}


/* YOUTUBE RESPONSIVE EMBED */
.embed-container { 
        position: relative; 
        padding-bottom: 56.25%; 
        height: 0; 
        overflow: hidden; 
        max-width: 100%; 
}

.embed-container iframe, 
.embed-container object, 
.embed-container embed { 
        position: absolute; 
        top: 0; 
        left: 0; 
        width: 100%; 
        height: 100%; 
}

#mc_embed_signup input.email {
        font-family: 'Asap' !important;
}

.dvhn {
        background-image: url(../img/dvhn.png);
        background-color: #fff;
        height: 100%;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);

        -webkit-background-position: center center;
        -moz-background-position: center center;
        -o-background-position: center center;
        background-position: center center;

        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
}

.twitter-timeline {
        margin: 0 auto;
}

footer {
        font-family: 'Asap', sans-serif;
        font-size: 16px;
        background: #4b5264;
        padding: 50px;
}
footer,
footer a {
        color: rgba(255, 255, 255, 0.7);
}
footer a:hover {
        color: rgba(255, 255, 255, 1);
}

.border-white{
        border-top: 2px solid white;
}

.social i {
        width: 40px;
        height: 40px;
        line-height: 40px;
        margin-top: 20px;
        border-radius: 50px;
        font-size: 20px;
        color: #fff;
        border: 1px solid #fff;
}

footer .fa-envelope {
        -ms-transform: rotate(10deg); /* IE 9 */
        -webkit-transform: rotate(10deg); /* Chrome, Safari, Opera */
        transform: rotate(10deg);
}


/*-----------------
8. Promo-Video
------------------*/

.promo-video {
    position: relative;

    .play-btn {
        position: absolute;
        left: 0;
        top: 50px;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 9;
        width: 70px;
        height: 70px;
        text-align: center;
        font-size: 18px;
        line-height: 3.4;
        border-radius: 50%;
        outline: none;
    }
}

.waves-block {
    position: absolute;
    width: 384px;
    width: 24rem;
    height: 384px;
    height: 24rem;
    left: 50%;
    top: 55%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 2;

    .waves {
        position: absolute;
        width: 384px;
        width: 384px;
        width: 24rem;
        height: 384px;
        height: 384px;
        height: 24rem;
        background: rgba(255, 255, 255, 0.2);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        border-radius: 320px;
        background-clip: padding-box;
        -webkit-animation: waves 3s ease-in-out infinite;
        animation: waves 3s ease-in-out infinite;
    }
    .wave-1 {
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
    }
    .wave-2 {
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
    }
    .wave-3 {
        -webkit-animation-delay: 2s;
        animation-delay: 2s;
    }
}

@-webkit-keyframes waves {
    0% {
        -webkit-transform: scale(0.2, 0.2);
        transform: scale(0.2, 0.2);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    50% {
        opacity: 0.9;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    }
    100% {
        -webkit-transform: scale(0.9, 0.9);
        transform: scale(0.9, 0.9);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
}

@keyframes waves {
    0% {
        -webkit-transform: scale(0.2, 0.2);
        transform: scale(0.2, 0.2);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    50% {
        opacity: 0.9;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    }
    100% {
        -webkit-transform: scale(0.9, 0.9);
        transform: scale(0.9, 0.9);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
}



@media (max-width: 568px) {
    .padding {
        padding: 20px 30px;
    }
    .topimage {
        width: 60px;
    }
    .opening {
        font-size: 2.2em;
    }
    h1 {
        font-size: 2.2em;
        margin-bottom: 0;
    }
    #Sec1,#Sec2,#Sec3,#Sec4,#sec5,#sec6 {
        margin: 10px 0 20px 0;        
    }
    #Sec3b {
        margin-top: -40px;      
    }
    .pure-u-1 img {
        max-width: 75%;
        margin: 0 auto;
    }
    .wrapper p {
        text-align: center;
        padding: 20px;
    }
    button {
       margin: 0 10px;
    }
    .form-group {
        -webkit-flex-direction: column;
        flex-direction: column;
    }
    .form-group label,
    .form-group input {
        width: 94%;
        margin: 0 auto;
    }
    .form h1 {
        text-align: center;
    }
}

.errorpage {
        width: 500px;
        max-width: 100%;
        margin: 8vh auto;
        text-align: center;
        font-weight: bold;
}

.errorpage img {
        width: 40%;
}

/* Spacing css */
@import "resources/assets/sass/_mediaqueries.scss";

