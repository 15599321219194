/* Color variables */

$orange: #f0974b;

/* ANIMATION SETTINGS */

.delay-005 {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
}
.delay-01 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}
.delay-015 {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
}
.delay-02 {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.delay-025 {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.delay-03 {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
}
.delay-035 {
    -webkit-animation-delay: 3.5s;
    animation-delay: 3.5s;
}
.delay-04 {
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
}
.delay-045 {
    -webkit-animation-delay: 4.5s;
    animation-delay: 4.5s;
}
.delay-05 {
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
}
.delay-055 {
    -webkit-animation-delay: 5.5s;
    animation-delay: 5.5s;
}
.delay-06 {
    -webkit-animation-delay: 6s;
    animation-delay: 6s;
}
